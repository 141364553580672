import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'
import { isFile } from 'utils/miscHelper'

export const getActivity = async (data) => {
  try {
    const response = await client.post('/act-delete-upd/getActivity', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};


export const getTouchpointNameList = async (data) => {
  try {
    const response = await client.post('/act-delete-upd/getTouchpointNameList', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getSchedule = async (data) => {
  try {
    const response = await client.post('/act-delete-upd/getSchedule', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getDeleteHistory = async (data) => {
  try {
    const response = await client.post('/act-delete-upd/getDeleteHistory', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const uploadDeleteMessage = async (data) => {
  try {
    // const formData = new FormData();
    // formData.append("deleteMessage", JSON.stringify({deleteMessage: JSON.stringify(data.result), activityId: data.activity_id, fileName: data.file.name}))
    // if (isFile(data.file)) {
    //   formData.append("uploadFile", data.file)
    // }
    const Data = JSON.stringify({deleteMessage: JSON.stringify(data.result), activityId: data.activity_id, fileName: data.file.name})
    const response = await client.post('/act-delete-upd/uploadDeleteMessage', {deleteMessage: Data});
    
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const checkAllIsUserLevel = async (data) => {
  try {
    //const Data = JSON.stringify({deleteMessage: JSON.stringify(data.result), activityId: data.activity_id, fileName: data.file.name})
    const response = await client.post('/act-delete-upd/checkAllIsUserLevel', {activityId: data.activity_id});
    
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const deleteMessageConfirm = async (data) => {
  try {
    const response = await client.post('/act-delete-upd/deleteMessageConfirm', {saveData: JSON.stringify(data.saveData),activityId: data.activityId, actDeleteUpd: data.actDeleteUpd});

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const exportAllSchedule = async (data) => {
  try {
    const response = await client.post('/act-delete-upd/exportAllSchedule', {activityId: data});

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const exportSchedule = async (data) => {
  try {
    const response = await client.post('/act-delete-upd/exportSchedule', {id: data});

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const exportDeleteMessage = async (data) => {
  try {
    const response = await client.post('/act-delete-upd/exportDeleteMessage', {activityId: data.actId, date: data.date, by: data.by});

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};