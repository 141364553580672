import client from './instance'

export const getToken = async () => {
    try {
      const response = await client.post('/dashboard/getToken');

      return response.data;
    } catch (error) {
      console.log(error)
      throw error;
    }
};

export const getDashboard = async (data) => {
  try {
    const response = await client.post('/dashboard/getDashboard', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};