import { Constants } from '../views/customerSurvey/constants';
import { createBy, updateBy } from 'utils/apiHelper'
import client from './instance'
import client2 from './instanceNoLodding'

const mainAPI = '/customer-survey-result';
const subAPI = {
    search: '/search',
    downloadReport: '/download-report',
}

export const downloadReport = async (data) => {
    try {
        console.log('[downloadReport] data =', data);

        const response = await client.post(mainAPI + subAPI.downloadReport, data);
        console.log('[downloadReport] response =', response);
        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const search = async (data) => {
    try {
        console.log('[search] data =', data);

        const response = await client.post(mainAPI + subAPI.search, data);
        console.log('[search] response =', response);
        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    }
}